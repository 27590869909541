<template>
<div>

    <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 4%" v-show="!$vuetify.breakpoint.mobile"></v-row>
    <v-row class="align-center d-flex h-0 pb-20">
        <v-col lg="4" sm="6" class="mx-auto" style="padding: 0px 0px 0px 0px">
            <v-card class="" style="">
                <v-card-title class="text-primary" style="
              justify-content: center;
              font-weight: 600 !important;
              padding-bottom: 20px;
              font-size: 24px;
            ">
                    <span>เข้าสู่ระบบโรงพยาบาล/คลินิก</span>
                </v-card-title>

                <v-card-subtitle style="
              height: 23px;
              text-align: center !important;
              background-color: rgba(255, 201, 10, 0.082) !important;
              color: #a84192;
            ">
                    <span>
                        <li>Healthcare provider</li>
                    </span>
                </v-card-subtitle>

                <v-card-text style="border-radius: 7px; padding-top: 25px">
                    <!-- user_provider -->
                    <v-row v-if="user_type == 'user_provider'" dense align="center" style="text-align: center;">
                        <v-form ref="form" v-model="valid" lazy-validation style="width: 100%; padding-top: 50px;">
                            <span style="font-weight: 600; color: #a84192 !important;">PASSCODE</span>
                            <v-text-field counter="6" v-model="name" :rules="nameRules" label="Passcode 6 หลัก" dense outlined required style="padding-top: 20px"></v-text-field>

                        </v-form>
                        <v-row>
                            <v-col style="text-align: right;"><a href="#">ลืมรหัสผ่าน</a></v-col>
                        </v-row>

                    </v-row>

                    <!-- user_osm -->
                    <v-row v-if="user_type == 'user_osm'" dense align="center" style="text-align: center;" >
                        <v-form ref="form" v-model="valid" lazy-validation style="width: 100%;  padding-top: 50px; ">
                            <span style="font-weight: 600; color: #a84192 !important;">PASSCODE</span>
                            <v-text-field counter="6" v-model="name" :rules="nameRules" label="Passcode 6 หลัก" dense outlined required style="padding-top: 20px"></v-text-field>
                        </v-form>
                        <v-row>
                            <v-col style="text-align: right;"><a href="#">ลืมรหัสผ่าน</a></v-col>
                        </v-row>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
        <v-btn outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
            <v-icon small>west</v-icon>
        </v-btn>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
            ยืนยันข้อมูล
        </v-btn>
    </v-row>

    <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
        <v-row class="align-center" justify="center">
            <v-btn outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                ยืนยันข้อมูล
            </v-btn>
        </v-row>
    </v-footer>
</div>
</template>

<script>
export default {
    name: "register-select-type",
    data() {
        return {
            activePicker: null,
            date: null,
            menu: false,
            user_type: "user_osm",
            pdpa_accept: false,
            valid: true,
            name: "",
            nameRules: [
                (v) => !!v || "โปรดกรอกข้อมูล",
                (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
            ],
            email: "",
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            select: null,
            items: ["Item 1", "Item 2", "Item 3", "Item 4"],
            checkbox: false,
            loading: false,
            snackbar: false,
            snackbarColor: 'default',
            otp: '',
            text: '',
            expectedOtp: '133707',
        };
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },
    methods: {
        save(date) {
            this.$refs.menu.save(date)
        },
        validate() {
            this.$refs.form.validate();
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        onFinish(rsp) {
            this.loading = true
            setTimeout(() => {
                this.loading = false
                this.snackbarColor = (rsp === this.expectedOtp) ? 'success' : 'warning'
                this.text = `Processed OTP with "${rsp}" (${this.snackbarColor})`
                this.snackbar = true
            }, 3500)
        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #FFFFFF;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #A84192;
}

.v-main__wrap {

    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}
</style>
